

const getBaseUrl = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/wow/wow-api/api`;
        return apiUrl;
    }
    else{
        return "";
    }
}

const getTemplate = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/wow/wow-api/template_wow.xlsx`;
        return apiUrl;
    }
    else{
        return "";
    }
}


const appConfig = {
    //pathUserTemplate: 'https://wow-api.eventqiu.com/template_wow.xlsx',
    pathUserTemplate: getTemplate(),
    pathCodeTemplate: '',
    apiPrefix: 'https://wow-api.eventqiu.com/api',
    //apiPrefix: getBaseUrl(),
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig

